<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" xs="12" md="8" class="pa-0" :class="!affichagePortable ? 'pl-8':''">
				<v-row v-if="affichagePortable" class="ma-0">
					<v-btn-toggle v-model="mode_affichage" mandatory dense>
						<v-btn value="gardes" :disabled="!gardes_accessibles" active-class="bouton_gardes">
							<span>gardes</span>
							<!-- <v-icon>mdi-format-align-left</v-icon> -->
						</v-btn>
						<v-btn value="dispos" active-class="bouton_dispos">
							<span>dispos</span>
							<!-- <v-icon>mdi-format-align-left</v-icon> -->
						</v-btn>
					</v-btn-toggle>
					<div class="flex-grow-1"></div>
					<v-fade-transition mode="out-in">
						<div key="dispos" v-if="mode_affichage == 'dispos' && !gestion_agatt">
							<v-btn icon color="primary" class="mr-6" @click="affichage_commentaire_portable = !affichage_commentaire_portable" :disabled="!autorisation_sauvegarde_dispos"><v-icon>{{ commentaire_planning != '' && commentaire_planning != null ? 'mdi-comment-text' : 'mdi-comment-text-outline' }}</v-icon></v-btn>
							<v-btn :key="mode_affichage" icon color="primary" @click="onClickSauvDispos" :disabled="!autorisation_sauvegarde_dispos" :loading="chargement_sauvegarde"><v-icon>mdi-content-save</v-icon></v-btn>
						</div>
						<v-btn v-if="mode_affichage == 'gardes' && activation_agenda_google" key="gardes" icon color="primary" @click="onClickSauvGoogle" :loading="chargement_google"><v-icon>mdi-google-plus</v-icon></v-btn>
					</v-fade-transition>
				</v-row>

				<v-expand-transition v-if="affichagePortable">
					<v-row v-show="affichage_commentaire_portable && mode_affichage == 'dispos'" class="ma-0 mt-6">
						<v-textarea hide-details outlined label="Commentaire du mois" :disabled="!autorisation_sauvegarde_dispos" v-model="commentaire_planning"></v-textarea>	
					</v-row>
				</v-expand-transition>

				<v-row align="center" class="ma-0 mb-4 px-4 mt-4" :class="affichagePortable ? 'mt-8' : ''">
					<v-btn fab outlined small @click="mois_precedent" :loading="chargement_mois_precedent">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-slide-y-transition mode="out-in">
						<div class="flex-grow-1 text-center primary--text text-h5 font-weight-light" :key="titre_mois">{{ titre_mois }}</div>
					</v-slide-y-transition>
					<v-btn fab outlined small @click="mois_suivant" :loading="chargement_mois_suivant">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0">
					<v-calendar id="calendrier_dispos_gardes" :class="affichagePortable ? 'calendrier_portable':'calendrier_normal'" ref="calendar" type="month" 
								locale = 'fr'
								v-model="start.date" 
								:weekdays="jours_semaine" 
								@change="updateRange">
								<template v-slot:day="{day, date}">
									<v-fade-transition mode="out-in">
										<v-row :key="planning_courant? planning_courant.id : null" v-if="affichageJour(date)" class="ma-0" :class="affichagePortable ? 'zone_gardes_dispos_portable': 'zone_gardes_dispos_normal'">
											<v-card v-for="creneau in creneaux" :key="creneau.id" :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'" height="100%" class="pa-0" :color="couleurCard(date, creneau)" :class="classeCard(date, creneau)" :disabled="disabledCard(date, creneau)" @click="onClickCreneau(date, creneau)" ripple flat tile>
												<v-col class="ma-0 pa-0" style="height: 100%">
													<v-row class="ma-0 pa-0" align="center" justify="center" style="height: 100%">
														<div v-if="texteCard(date, creneau).type == 'texte'" class="font-weight-bold white--text">{{texteCard(date, creneau).texte}}</div>
														<v-icon v-else color="light-green lighten-1">{{ texteCard(date, creneau).texte }}</v-icon>
													</v-row>
												</v-col>
											</v-card>
										</v-row>
									</v-fade-transition>
								</template>
								<template v-slot:day-label="{day, date}">
									<v-sheet class="py-1" :color="couleurJourAstreinte(date)">
										<v-btn depressed small fab @click="onClickJour(date)">{{ day}}</v-btn>
									</v-sheet>
								</template>
					</v-calendar>	
				</v-row>
				<v-row class="ma-0" v-if="!gestion_agatt">
					<v-fade-transition mode="out-in">
						<v-alert :key="message_statut_planning.message" border="left" :width="affichagePortable ? '100%' : ''" :dense="affichagePortable" :type="message_statut_planning.type" :class="affichagePortable ? 'mt-2 mb-0':'mt-12'" v-if="message_statut_planning.message">
							{{ message_statut_planning.message }}
						</v-alert>
                    </v-fade-transition>
				</v-row>
			</v-col>
			<v-col md="4" class="pa-0 pl-12" v-if="!affichagePortable">
				<v-row class="ma-0">
					<v-btn-toggle v-model="mode_affichage" mandatory dense>
						<v-btn value="gardes" :disabled="!gardes_accessibles" active-class="bouton_gardes">
							<span>gardes</span>
							<!-- <v-icon>mdi-format-align-left</v-icon> -->
						</v-btn>
						<v-btn value="dispos" active-class="bouton_dispos">
							<span>dispos</span>
							<!-- <v-icon>mdi-format-align-left</v-icon> -->
						</v-btn>
					</v-btn-toggle>
					<div class="flex-grow-1"></div>
					<v-tooltip left v-if="mode_affichage == 'dispos' && !gestion_agatt">
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" color="primary" @click="onClickSauvDispos" :disabled="!autorisation_sauvegarde_dispos" :loading="chargement_sauvegarde"><v-icon>mdi-content-save</v-icon></v-btn>
						</template>
						<span>Sauvegarder les dispos</span>
					</v-tooltip>
					<v-tooltip left v-if="mode_affichage == 'gardes' && activation_agenda_google">
						<template v-slot:activator="{ on }">
							<v-btn icon v-on="on" color="primary" @click="onClickSauvGoogle" :loading="chargement_google"><v-icon>mdi-google-plus</v-icon></v-btn>
						</template>
						<span>Ajouter à un agenda Google</span>
					</v-tooltip>
				</v-row>
				<v-row class="ma-0 mt-12" v-if="mode_affichage == 'dispos' && !gestion_agatt">
					<v-fade-transition mode="out-in">
						<v-textarea v-if="mode_affichage == 'dispos'" :key="mode_affichage" outlined label="Commentaire du mois" :disabled="!autorisation_sauvegarde_dispos" v-model="commentaire_planning"></v-textarea>
                    </v-fade-transition>
				</v-row>
				<v-row class="ma-0 mt-12" v-if="mode_affichage == 'gardes' && !gestion_agatt">
					<v-fade-transition mode="out-in">
						<v-col v-if="mode_affichage == 'gardes' && affichage_zone_remplacants" class="pa-0" :key="affichage_zone_remplacants">
								<v-toolbar dark flat color="light-green darken-2">
									<v-toolbar-title>
										<div>SPV disponibles</div>
										<v-slide-x-transition mode="out-in">
											<div :key="remplacement.id_garde" class="text-caption">{{remplacement.date+' ('+remplacement.nom_creneau+')'}}</div>
										</v-slide-x-transition>
									</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-progress-circular v-if="chargement_remplacants" indeterminate color="light-green lighten-3"></v-progress-circular>
									<v-chip v-else color="light-green lighten-3" class="light-green--text text--darken-2 font-weight-bold">{{ remplacement.remplacants.length + remplacement.echanges.length }}</v-chip>
								</v-toolbar>
								<v-expand-transition>
									<div v-if="!chargement_remplacants" class="liste_dispo_remplacant">
											<v-toolbar dense flat color="light-green lighten-3" height="25">
												<v-toolbar-title class="font-weight-light text-body-2">Remplaçants</v-toolbar-title>
											</v-toolbar>
											<v-row class="ma-0">
												<!-- <template v-if="existeDejaDemande">
													<div>Une demande de remplacement ou d'échange est déjà en cours pour cette garde</div>
												</template> -->
												<template v-if="remplacement.remplacants.length > 0">
													<v-list  class="pa-0" width="100%" dense>
														<template v-for="(remplacant, index) in remplacement.remplacants">
															<v-list-item class="px-1" :key="remplacant.id">
																<v-list-item-avatar color="light-green lighten-3" size="30">
																	<img v-if="remplacant.plannification.membre.user.photo" :src="getPhoto(remplacant.plannification.membre.user.photo)">
																	<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(remplacant.plannification.membre.user.prenom)+premiereLettreMaj(remplacant.plannification.membre.user.nom)}}</span>
																</v-list-item-avatar>

																<v-list-item-content>
																	<v-list-item-title>{{ remplacant.plannification.membre.user.nom + ' ' +  remplacant.plannification.membre.user.prenom}}</v-list-item-title>
																</v-list-item-content>
																<!-- <v-list-item-action>
																	<v-checkbox class="mr-1" hide-details v-model="remplacement.remplacants_choisis" :value="remplacant.plannification.id_membre"></v-checkbox>
																</v-list-item-action> -->
															</v-list-item>
															<v-divider light v-if="index + 1 < remplacement.remplacants.length" :key="'divider'+index"></v-divider>
														</template>
													</v-list>
													<!-- <v-row class="ma-0 mt-3" justify="end">
														<v-btn small outlined color="light-green" :disabled="remplacement.remplacants_choisis.length == 0">Demander</v-btn>
													</v-row> -->
												</template>
												<v-row class="ma-0 mt-4 mb-4" v-else justify="center">
													<v-col class="pa-0">
														<div class="font-italic text-center">Aucun remplaçant disponible</div>
														<!-- <div class="text-center mt-3"><v-btn small outlined color="light-green" disabled><v-icon left dark>mdi-lifebuoy</v-icon>SOS: demander à tous</v-btn></div> -->
													</v-col>
												</v-row>
											</v-row>
											<v-toolbar class="mt-4" dense flat color="light-green lighten-3" height="25">
												<v-toolbar-title class="font-weight-light text-body-2">Echanges</v-toolbar-title>
											</v-toolbar>
											<v-row class="ma-0">
												<v-list two-line v-if="remplacement.echanges.length > 0" class="pa-0" width="100%" dense>
													<template v-for="(echange, index) in remplacement.echanges">
														<v-list-item class="px-1" :key="echange.id" @mouseenter="montrerEchange(echange)" @mouseleave="resetEchangeCourant" style="cursor: pointer">
															<v-list-item-avatar color="light-green lighten-3" size="30" class="my-0">
																<img v-if="echange.plannification.membre.user.photo" :src="getPhoto(echange.plannification.membre.user.photo)">
																<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(echange.plannification.membre.user.prenom)+premiereLettreMaj(echange.plannification.membre.user.nom)}}</span>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title>{{ echange.plannification.membre.user.nom + ' ' +  echange.plannification.membre.user.prenom}}</v-list-item-title>
																<v-list-item-subtitle>{{ texteEchange(echange.date, echange.periode.nom) }}</v-list-item-subtitle>
															</v-list-item-content>
														</v-list-item>
														<v-divider light v-if="index + 1 < remplacement.echanges.length" :key="'divider'+index"></v-divider>
													</template>
												</v-list>
												<v-row class="mx-0 mt-4" v-else justify="center">
													<div class="font-italic">Aucun échange possible</div>
												</v-row>
											</v-row>
									</div>
									
								</v-expand-transition>
						</v-col>
					</v-fade-transition>
				</v-row>
			</v-col>
		</v-row>

		<!-- dialogue detail jour -->
        <v-dialog v-model="openDialogueDetailJour" max-width="400" @click:outside="annuleDetailJour">
            <v-card>
				<v-row align="center" class="ma-0 pa-3">
					<v-btn fab outlined x-small @click="detail_jour_precedent" :disabled=" start != null && formulaireDetailJour.jour == start.date">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-fade-transition mode="out-in">
						<div class="flex-grow-1 text-center text-h6" :key="formulaireDetailJour.titre">{{ formulaireDetailJour.titre }}</div>
					</v-fade-transition>
					<v-btn fab outlined x-small @click="detail_jour_suivant" :disabled=" end != null && formulaireDetailJour.jour == end.date">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-row>
                <v-card-text>
					<v-row class="ma-0" justify="center">
						<v-fade-transition mode="out-in">
							<div class="message_astreinte_detail mb-2" :key="formulaireDetailJour.message_atreinte">
								<v-icon v-if="formulaireDetailJour.message_atreinte != ''" class="mr-3">mdi-information-outline</v-icon><span>{{ formulaireDetailJour.message_atreinte }}</span>
							</div>
						</v-fade-transition>
					</v-row>
					<v-row class="ma-0">
						<v-fade-transition mode="out-in">
							<v-row :key="formulaireDetailJour.jour" class="ma-0">
								<v-card v-for="creneau in creneaux" :key="creneau.id" outlined :width="(100/creneaux.length)+'%'" :max-width="(100/creneaux.length)+'%'" height="80" class="pa-0" :color="couleurCard(formulaireDetailJour.jour, creneau)" :disabled="disabledCard(formulaireDetailJour.jour, creneau)" @click="onClickCreneau(formulaireDetailJour.jour, creneau)" ripple flat tile>
									<v-card-text>
										<div class="text-center font-weight-bold">{{affichageHeure(creneau.heure_debut)}}<v-icon class="mx-1">mdi-arrow-right-thick</v-icon>{{affichageHeure(creneau.heure_fin)}}</div>
										<div class="text-center">{{ creneauJourDetail(formulaireDetailJour.jour, creneau) }}</div>
									</v-card-text>
								</v-card>
							</v-row>
						</v-fade-transition>
						
						<v-expand-transition>
							<v-row v-if="affichage_zone_remplacants_portable && mode_affichage == 'gardes'" class="ma-0 mt-2">
								<v-toolbar dark flat color="light-green darken-2">
									<v-toolbar-title>
										<div>SPV disponibles</div>
										<v-slide-x-transition mode="out-in">
											<div :key="remplacement.id_garde" class="text-caption">{{remplacement.date+' ('+remplacement.nom_creneau+')'}}</div>
										</v-slide-x-transition>
									</v-toolbar-title>
									<v-spacer></v-spacer>
									<v-progress-circular v-if="chargement_remplacants" indeterminate color="light-green lighten-3"></v-progress-circular>
									<v-chip v-else color="light-green lighten-3" class="light-green--text text--darken-2 font-weight-bold">{{ remplacement.remplacants.length + remplacement.echanges.length }}</v-chip>
								</v-toolbar>
								<v-expand-transition>
									<v-row class="ma-0" v-show="!chargement_remplacants">
										<v-col class="pa-0">
											<v-toolbar dense flat color="light-green lighten-3" height="25" width="100%">
												<v-toolbar-title class="font-weight-light text-body-2">Remplaçants</v-toolbar-title>
											</v-toolbar>
											<v-row class="ma-0">
												<v-list v-if="remplacement.remplacants.length > 0" class="pa-0" width="100%" dense>
													<template v-for="(remplacant, index) in remplacement.remplacants">
														<v-list-item class="px-1" :key="remplacant.id">
															<v-list-item-avatar color="light-green lighten-3" size="30">
																<img v-if="remplacant.plannification.membre.user.photo" :src="getPhoto(remplacant.plannification.membre.user.photo)">
																<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(remplacant.plannification.membre.user.prenom)+premiereLettreMaj(remplacant.plannification.membre.user.nom)}}</span>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title>{{ remplacant.plannification.membre.user.nom + ' ' +  remplacant.plannification.membre.user.prenom}}</v-list-item-title>
															</v-list-item-content>
														</v-list-item>
														<v-divider light v-if="index + 1 < remplacement.remplacants.length" :key="'divider'+index"></v-divider>
													</template>
												</v-list>
												<v-row class="mx-0 mt-4 mb-4" v-else justify="center">
													<div class="font-italic">Aucun remplaçant disponible</div>
												</v-row>
												<!-- <v-row class="ma-0 mt-4 mb-4" justify="center">
													<v-btn small outlined color="light-green" disabled><v-icon left dark>mdi-lifebuoy</v-icon>SOS: demander à tous</v-btn>
												</v-row> -->
											</v-row>
											<v-toolbar class="mt-4" dense flat color="light-green lighten-3" height="25" width="100%">
												<v-toolbar-title class="font-weight-light text-body-2">Echanges</v-toolbar-title>
											</v-toolbar>
											<v-row class="ma-0">
												<v-list two-line v-if="remplacement.echanges.length > 0" class="pa-0" width="100%" dense>
													<template v-for="(echange, index) in remplacement.echanges">
														<v-list-item class="px-1" :key="echange.id" @mouseenter="montrerEchange(echange)" @mouseleave="resetEchangeCourant">
															<v-list-item-avatar color="light-green lighten-3" size="30" class="my-0">
																<img v-if="echange.plannification.membre.user.photo" :src="getPhoto(echange.plannification.membre.user.photo)">
																<span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(echange.plannification.membre.user.prenom)+premiereLettreMaj(echange.plannification.membre.user.nom)}}</span>
															</v-list-item-avatar>

															<v-list-item-content>
																<v-list-item-title>{{ echange.plannification.membre.user.nom + ' ' +  echange.plannification.membre.user.prenom}}</v-list-item-title>
																<v-list-item-subtitle>{{ texteEchange(echange.date, echange.periode.nom) }}</v-list-item-subtitle>
															</v-list-item-content>
														</v-list-item>
														<v-divider light v-if="index + 1 < remplacement.echanges.length" :key="'divider'+index"></v-divider>
													</template>
												</v-list>
												<v-row class="ma-0 mt-4" v-else justify="center">
													<div class="font-italic">Aucun échange possible</div>
												</v-row>
											</v-row>
										</v-col>
									</v-row>
								</v-expand-transition>
							</v-row>
							
						</v-expand-transition>
					</v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import Moment from 'moment'
	Moment.locale('fr')
	import { extendMoment } from 'moment-range'
	import ICalParser from 'ical-js-parser'
	const moment = extendMoment(Moment)
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Planning')
			this.fetchCalendrierAgatt()
        },
        mounted() {
			this.$refs.calendar.checkChange()
			this.surveilleEnregistrementGoogle()
						
        },
        data() {
			return {
				activation_agenda_google: false,
				affichage_commentaire_portable: false,
				affichage_zone_remplacants: false,
				affichage_zone_remplacants_portable: false,
				astreintes: [],
				autorisation_sauvegarde_dispos: true,
				calendrier_agatt: [],
				chargement_google: false,
				chargement_mois_precedent: false,
				chargement_mois_suivant: false,
				chargement_planning: false,
				chargement_remplacants: false,
				chargement_sauvegarde: false,
				commentaire_planning: '',
				echange_courant: {
					date: null,
					id_creneau: null
				},
				
				formulaireDetailJour: {
					jour: null,
					message_atreinte: '',
					titre: ''
				},
				gardes_dispos: [],
				liste_messages_entrants: [],
				liste_messages_sortants: [],
				message_statut_planning: {
					type: null,
					message: ''
				},
				mode_affichage: 'dispos',
				end: null,
				gardes_accessibles: false,
				jours_semaine: [1,2,3,4,5,6,0],
				liste_gardes: [],
				openDialogueDetailJour: false,
				planning_courant: null,
				remplacement: {
					date: null,
					id_garde: null,
					nom_creneau: null,
					remplacants: [],
					remplacants_choisis: [],
					echanges: []
				},
				start: '2019-01-01'	// date aléatoire initiale
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			// astreintes() {
			// 	if(!this.start || !this.end) return []
			// 	if(!this.$store.getters.membreCourant.equipe) return []
			// 	return this.$store.getters.membreCourant.equipe.astreintes.filter( astreinte => moment(astreinte.date_debut).isBefore(moment(this.end.date)) && moment(astreinte.date_fin).isAfter(moment(this.start.date)))
			// },			
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			lien_agatt() {
                return this.$store.getters.utilisateurCourant.lien_agatt
            },
			evenements() {
				return []
			},
			gestion_agatt() {
				//	renvoie true si le centre est géré avec agatt
				return this.centre_courant.parametres.includes("gestion agatt")
			},
			messages_membre() {
				return this.$store.getters.messagesMembre
			},
			monthFormatter () {
				return this.$refs.calendar.getFormatter({
					timeZone: 'UTC', month: 'long',
				})
			},
			creneaux() {
				return this.$store.getters.membreCourant.centre.periodes
			},
			titre_mois() {
				const { start, end } = this
				if (!start || !end) {
					return ''
				}
				const startMonth = this.monthFormatter(start)
				const startYear = start.year
				return `${startMonth} ${startYear}`
			}
  
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			affichageHeure(heure) {
				// transforem HH:mm:ss en Hhmm
				return moment(heure, 'HH:mm:ss').format('H:mm')
			},
			affichageJour(date) {
				// renvoie true si le jour est entre date debut et date fin
				if(!this.start || !this.end) return false
				return moment(date).isBetween(this.start.date, this.end.date, null, '[]')
			},
			async ajouterEvents(gapi) {
				for (const garde of this.liste_gardes) {
					let debut_garde = ''
					let fin_garde = ''
					let rappel = 1440	//24h par défaut

					//	si heure debut > heure fin ou si heure de début = heure de fin (garde 24h), la garde se termine le jour d'apres
					if(moment(garde.periode.heure_debut, 'HH:mm:ss').isAfter(moment(garde.periode.heure_fin, 'HH:mm:ss')) || moment(garde.periode.heure_debut, 'HH:mm:ss') == moment(garde.periode.heure_fin, 'HH:mm:ss')) {
						debut_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_debut //décalage horaire par rapport a GMT non précisé car géré par l'attribut 'timezone'
						fin_garde = moment(garde.date).add(1,'days').format('YYYY-MM-DD')+'T'+garde.periode.heure_fin
					}
					
					else {
						debut_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_debut //décalage horaire par rapport a GMT non précisé car géré par l'attribut 'timezone'
						fin_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_fin
					}

					//	si l'heure de début est < 8:00:00 on fait un rappel 12h avant
					if(moment(garde.periode.heure_debut, 'HH:mm:ss').isBefore(moment('08:00:00', 'HH:mm:ss')) ) {
						rappel = 720
					}
					//	si l'heure de début est > 15:00:00 on fait un rappel 62h avant
					else if(moment(garde.periode.heure_debut, 'HH:mm:ss').isAfter(moment('15:00:00', 'HH:mm:ss'))) {
						rappel = 360
					}

					const evenement = {
								'summary': 'Garde CS',
								'source':{
									'title':'SP Planning',
									'url':'https://spplanning.fr'
								},
								'start': {
									'dateTime': debut_garde,
									'timeZone': 'Europe/Paris'
								},
								'end': {
									'dateTime': fin_garde,
									'timeZone': 'Europe/Paris'
								},
								'reminders': {
										'useDefault': false,
										'overrides': [
											{'method': 'popup', 'minutes': rappel}   
											]
								},
								'colorId': 11,
								'extendedProperties':{
									'private':{
										'origine':'SP Planning'
									}
								}
					}

					await this.sleep(500)		// Google n'autorise que 5 requetes/s max donc on est obligé d'attendre
					
					const requete_insertion = gapi.client.calendar.events.insert({
						calendarId: 'primary',
						resource: evenement
					})
					requete_insertion.execute((resp) => {
						if(!resp.error) {
							//nbre_garde += 1
						}
					})
							
				}
				const texte = this.liste_gardes.length > 1 ? 'Gardes ajoutées' : 'Garde ajoutée'
				this.snackbar('success', texte);
				this.chargement_google = false
			},
			annuleDetailJour() {
				this.openDialogueDetailJour = false
				this.formulaireDetailJour.titre = ''
				this.formulaireDetailJour.jour = null
				this.formulaireDetailJour.message_atreinte = ''
			},
			chargerDetailJour(date) {
				this.affichage_zone_remplacants_portable = false
				this.formulaireDetailJour.jour = date
				this.formulaireDetailJour.titre = moment(date).format('dddd DD/MM/YYYY')
				this.formulaireDetailJour.message_atreinte = this.estJourAstreinte(date) ? 'Vous êtes d\'astreinte ce jour.':''
			
			
			},
			chercherRemplacant(id_garde) {
				this.chargement_remplacants = true
				this.remplacement.remplacants_choisis = []
				//this.affichage_zone_remplacants_portable = false
				axios.post('api/planning/remplacants', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id ,id_garde: id_garde })
					.then( (reponse) => {
						//this.remplacement.remplacants = reponse.data.remplacants

						//	 on réorganise les remplacants par ordre alphabétique de l'utilisateur
						this.remplacement.remplacants = reponse.data.remplacants ? reponse.data.remplacants.sort((a,b) => {
							if(a.plannification.membre.user.nom.toLowerCase() < b.plannification.membre.user.nom.toLowerCase()) {
								return -1
							}
							else if(a.plannification.membre.user.nom.toLowerCase() > b.plannification.membre.user.nom.toLowerCase()) {
								return 1
							}

							//	ici on a le même nom donc on tri par prénom
							if(a.plannification.membre.user.prenom.toLowerCase() < b.plannification.membre.user.prenom.toLowerCase()) {
								return -1
							}
							else if(a.plannification.membre.user.prenom.toLowerCase() > b.plannification.membre.user.prenom.toLowerCase()) {
								return 1
							}

							return 0
							}) : []

						this.remplacement.echanges = reponse.data.echanges ? reponse.data.echanges.sort((a,b) => {
							if(a.plannification.membre.user.nom.toLowerCase() < b.plannification.membre.user.nom.toLowerCase()) {
								return -1
							}
							else if(a.plannification.membre.user.nom.toLowerCase() > b.plannification.membre.user.nom.toLowerCase()) {
								return 1
							}

							//	ici on a le même nom donc on tri par prénom
							if(a.plannification.membre.user.prenom.toLowerCase() < b.plannification.membre.user.prenom.toLowerCase()) {
								return -1
							}
							else if(a.plannification.membre.user.prenom.toLowerCase() > b.plannification.membre.user.prenom.toLowerCase()) {
								return 1
							}

							return 0
							}) : []
						
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors de la recherche de remplaçants');
                    })
                    .then(() => {
						this.chargement_remplacants = false
						//this.affichage_zone_remplacants_portable = true
                    })
			},
			classeCard(date, creneau) {
				//	si c'est un jour d'échange à montrer
				if(date == this.echange_courant.date && creneau.id == this.echange_courant.id_creneau) {
				 	return 'case_echange'
				}
				return ''
			},
			couleurCard(date, creneau) {
				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					if(periode) {
						if(this.mode_affichage == 'dispos' && periode.dispo == true) {
							return 'deep-purple lighten-4'
						}
						else if(this.mode_affichage == 'gardes' && periode.garde == true) {
							return 'light-green lighten-3'
						}
					}
				}

				// //	si c'est un jour d'échange à montrer
				// if(date == this.echange_courant.date && creneau.id == this.echange_courant.id_creneau) {
				// 	return 'deep-purple lighten-3'
				// }

				//	si c'est un jour d'astreinte on change la couleur, sinon on ne change pas la couleur
				return this.couleurJourAstreinte(date)
				
			},
			couleurJourAstreinte(date) {
				let astreinte = false
				this.astreintes.forEach(plage => {
					if(moment(date).isBetween(plage.date_debut, moment(plage.date_fin).subtract(1, 'days'), 'day', '[]')) astreinte = true
				})
				return astreinte ? 'amber lighten-5' : ''
			},
			creneauJourDetail(date, creneau) {
				//retourne le message à afficher dans le creneau ainsi que le style du message
				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					if(periode) {
						if(this.mode_affichage == 'dispos' && periode.dispo == true) {
							return 'disponible'
						}
						else if(this.mode_affichage == 'dispos' && periode.dispo == false) {
							return 'indisponible'
						}
						else if(this.mode_affichage == 'gardes' && periode.garde == true) {
							return 'de garde'
						}
						else if(this.mode_affichage == 'gardes' && periode.garde == false) {
							return 'pas de garde'
						}
					}
				}
				
				return ''
			},
			detail_jour_precedent() {
				this.chargerDetailJour(moment(this.formulaireDetailJour.jour).subtract(1, 'day').format('YYYY-MM-DD'))
			},
			detail_jour_suivant() {
				this.chargerDetailJour(moment(this.formulaireDetailJour.jour).add(1, 'day').format('YYYY-MM-DD'))
			},
			disabledCard(date, creneau) {
				//	si on est en mode affichage dispos et que l eplanning est en cours d'élaboration on désactive
				if(this.mode_affichage == 'dispos' && this.planning_courant && this.planning_courant.id_statut == 2) return true

				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					if(periode) {
						if(this.mode_affichage == 'gardes' && periode.garde == true) {
							return false
						}
						else if(this.mode_affichage == 'gardes' && periode.garde == false) {
							return true
						}
					}
				}
				return false
			},
			estJourAstreinte(date) {
				let astreinte = false
				this.astreintes.forEach(plage => {
					if(moment(date).isBetween(plage.date_debut, moment(plage.date_fin).subtract(1, 'days'), 'day', '[]')) astreinte = true
				})
				return astreinte
			},
			existeDejaDemande() {
				return true
			},
			fetchCalendrierAgatt() {
				//	récupération du calendrier AGATT qui contient les événements de J-3mois à J+12 mois
				if(this.gestion_agatt && this.lien_agatt) {
					this.chargement_mois_suivant = true
					this.chargement_mois_precedent = true

					axios.post('api/planning/calendrier_agatt', { 
							lien_agatt: this.lien_agatt 
						})
						.then( (reponse) => {
							//console.log(reponse.data.calendrier)
							this.calendrier_agatt = ICalParser.toJSON(reponse.data.calendrier).events
							this.fetchDisposGardesAGATT()
						})
						.catch( (error) => {
							this.snackbar('error', 'Erreur lors du chargement du calendrier AGATT');
						})
						.then(() => {
						this.chargement_mois_suivant = false
						this.chargement_mois_precedent = false
                    })
				}
			},
			fetchDisposGardes(post_sauvegarde = null) {
				if(!this.gestion_agatt) {
					//	on active les 2 loading si aucun ne l'est (cas du chargement initial)
					if(!this.chargement_mois_precedent && !this.chargement_mois_suivant) {
						this.chargement_mois_suivant = true
						this.chargement_mois_precedent = true
						
					}
					
					axios.post('api/planning/planning_indiv', { 
							id_membre:this.$store.getters.membreCourant.id, 
							id_centre: this.$store.getters.membreCourant.centre.id ,
							date_debut: this.start.date, 
							date_fin: this.end.date 
						})
						.then( (reponse) => {
							this.planning_courant = reponse.data.planning
							this.initialiserGardesDispos()
							
							//	si un planning est renvoyé
							if(reponse.data.planning) {
								if(reponse.data.planning.id_statut == 1) {
									this.gardes_accessibles = false
									this.autorisation_sauvegarde_dispos = true
									this.mode_affichage = 'dispos'
									this.message_statut_planning = {
										type: 'info',
										message: reponse.data.planning.date_limite_dispos ? 'Date limite de saisie des disponibilités: '+moment(reponse.data.planning.date_limite_dispos).format('DD/MM/YYYY')+ ' à '+moment(reponse.data.planning.date_limite_dispos).format('HH:mm') : ''
									}
								}
								else if(reponse.data.planning.id_statut == 2) {
									this.gardes_accessibles = false
									this.autorisation_sauvegarde_dispos = false
									this.mode_affichage = 'dispos'
									this.message_statut_planning = {
										type: 'warning',
										message: 'Le planning des gardes est en cours d\'élaboration: disponibilités verrouillées'
									}
								}
								else if(reponse.data.planning.id_statut == 3) {
									this.gardes_accessibles = true
									this.autorisation_sauvegarde_dispos = true
									// si on exécute la fonction après avoir sauvegarder les dispos on reste sur un affichage des dispos
									if(post_sauvegarde != null) {
										this.mode_affichage = 'dispos'
									}
									else {
										this.mode_affichage = 'gardes'
									}
									this.message_statut_planning = {
										type: null,
										message: ''
									}
								}
							}
							else {
								this.gardes_accessibles = false
								this.autorisation_sauvegarde_dispos = true
								this.mode_affichage = 'dispos'
								this.message_statut_planning = {
										type: null,
										message: ''
									}
							}

							this.astreintes = reponse.data.astreintes
						})
						.catch( (error) => {
							this.snackbar('error', 'Erreur lors du chargement des dispos et gardes');
						})
						.then(() => {
						this.chargement_mois_suivant = false
						this.chargement_mois_precedent = false
                    })
				}
				else {
					this.mode_affichage = 'gardes'
					//	on récupère quand meme les astreintes
					//	on active les 2 loading si aucun ne l'est (cas du chargement initial)
					if(!this.chargement_mois_precedent && !this.chargement_mois_suivant) {
						this.chargement_mois_suivant = true
						this.chargement_mois_precedent = true
						
					}

					axios.post('api/planning/planning_indiv', { 
							id_membre:this.$store.getters.membreCourant.id, 
							id_centre: this.$store.getters.membreCourant.centre.id ,
							date_debut: this.start.date, 
							date_fin: this.end.date 
						})
						.then( (reponse) => {
							this.astreintes = reponse.data.astreintes
						})
						.catch( (error) => {
							this.snackbar('error', 'Erreur lors du chargement des astreintes');
						})
						.then(() => {
							this.chargement_mois_suivant = false
							this.chargement_mois_precedent = false
                    })

				}
			},
			fetchDisposGardesAGATT() {
				if(this.gestion_agatt) {
					this.gardes_accessibles = true
					this.initialiserGardesDisposAGATT()
					this.chargement_mois_suivant = false
					this.chargement_mois_precedent = false
				}
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			initialiserGardesDispos() {
				
				let planning = []
				this.commentaire_planning = ''
				
				
				//	initialisation du nombre de creneau par jour à utiliser
				const creneaux_jour = []
				this.creneaux.forEach(creneau => {
					creneaux_jour.push({
						id_creneau: creneau.id,
						nom_creneau: creneau.nom,
						garde: false,
						dispo: false,
						id_garde: null
					})
				})

				if(this.start && this.end) {
					
					//	initialisation avec un planning avec aucune garde ni dispo
					const range = moment.range(moment(this.start.date), moment(this.end.date))
					for (let jour of range.by('day')) {
						planning.push({
							jour: jour.format('YYYY-MM-DD'),
							creneaux: JSON.parse(JSON.stringify(creneaux_jour))		// technique pour faire une 'deep copie' de creneaux_jour sinon on ne copie que des references et tous les objets pointent sur le même creneaux_jour
						})
					}
					
					//	ajout des dispos et gardes enregistrées
					if(this.planning_courant && this.planning_courant.plannifications.length > 0) {
						this.planning_courant.plannifications[0].gardes.forEach(gardedispo => {
							// format de gardedispo:
							// date: '2019-12-04
							// dispo: true/false
							// garde: true/false
							// id_periode: 1
							// id
							const indexPlanning = planning.findIndex( element => element.jour == gardedispo.date)
							//	le jour existe dans le tableau
							if(indexPlanning >= 0) {
								const indexCreneau = planning[indexPlanning].creneaux.findIndex( elem => elem.id_creneau == gardedispo.id_periode)
								//	si le créneau est trouvé
								if(indexCreneau >= 0){
									planning[indexPlanning].creneaux[indexCreneau].dispo = gardedispo.dispo
									planning[indexPlanning].creneaux[indexCreneau].garde = gardedispo.garde
									planning[indexPlanning].creneaux[indexCreneau].id_garde = gardedispo.id
								}
							}
						})
						this.commentaire_planning = this.planning_courant.plannifications[0].commentaire

						//	initialisation du tableau de gardes pour l'agenda Google
						this.liste_gardes = this.planning_courant.plannifications[0].gardes.filter( date => date.garde == true)
					}
				}
				this.gardes_dispos = planning
				
			},
			initialiserGardesDisposAGATT() {
				let planning = []
				let gardes_google = []
				
				//	initialisation du nombre de creneau par jour à utiliser
				const creneaux_jour = []
				this.creneaux.forEach(creneau => {
					creneaux_jour.push({
						id_creneau: creneau.id,
						nom_creneau: creneau.nom,
						garde: false,
						dispo: false,
						id_garde: null
					})
				})

				if(this.start && this.end) {
					
					//	initialisation avec un planning avec aucune garde ni dispo
					const range = moment.range(moment(this.start.date), moment(this.end.date))
					for (let jour of range.by('day')) {
						planning.push({
							jour: jour.format('YYYY-MM-DD'),
							creneaux: JSON.parse(JSON.stringify(creneaux_jour))		// technique pour faire une 'deep copie' de creneaux_jour sinon on ne copie que des references et tous les objets pointent sur le même creneaux_jour
						})
					}

					//	ajout des gardes et dispos depuis le calendrier AGATT
					if(this.calendrier_agatt.length > 0) {
						for (const evenement of this.calendrier_agatt) {
							//	format evenement (donnée utiles):
							//	dtstart: {
							//		value:	20220130T070000Z
							//	},
							//	dtend: {
							//		value:	20220131T190000Z
							//	},
							//	status:"CONFIRMED" 
							//	location:"BOURGES DANJONS"
							//	summary:"Dispo 12h Jour" ou "Dispo 12h Nuit" ou "Garde 12H jour" ou "Garde 12H nuit" ou "Garde 24H"

							//console.log(evenement.dtstart.value)

							//	on teste si la date de début est dans le mois concerné et si l'événement est confirmé
							if(moment(evenement.dtstart.value).isSameOrAfter(moment(this.start.date)) && evenement.status == 'CONFIRMED') {
								
								const indexPlanning = planning.findIndex( element => element.jour == moment(evenement.dtstart.value).format('YYYY-MM-DD'))
								//	le jour existe dans le tableau
								if(indexPlanning >= 0) {

									const summary = evenement.summary.toLowerCase()
									const indexCreneauNuit = planning[indexPlanning].creneaux.findIndex( elem => elem.nom_creneau.toLowerCase() == 'nuit')
									const indexCreneauJour = planning[indexPlanning].creneaux.findIndex( elem => elem.nom_creneau.toLowerCase() == 'jour')

									//	Si dispo 12h jour
									if(summary.includes('dispo 12h jour') && indexCreneauJour >= 0) {
										planning[indexPlanning].creneaux[indexCreneauJour].dispo = true
									}
									//	Si dispo 12h nuit
									else if(summary.includes('dispo 12h nuit') && indexCreneauNuit >= 0) {
										planning[indexPlanning].creneaux[indexCreneauNuit].dispo = true
									}
									//	Si garde 12h jour
									else if(summary.includes('garde 12h jour') && indexCreneauJour >= 0) {
										planning[indexPlanning].creneaux[indexCreneauJour].garde = true

										//	ajout à la liste des gardes pour Google
										const periode = this.creneaux.find( creneau => creneau.nom.toLowerCase() == 'jour')
										if(periode) {
											gardes_google.push({
												date: moment(evenement.dtstart.value).format('YYYY-MM-DD'),
												periode: periode
											})
										}
									}
									//	Si garde 12h nuit
									else if(summary.includes('garde 12h nuit') && indexCreneauNuit >= 0) {
										planning[indexPlanning].creneaux[indexCreneauNuit].garde = true
										
										//	ajout à la liste des gardes pour Google
										const periode = this.creneaux.find( creneau => creneau.nom.toLowerCase() == 'nuit')
										if(periode) {
											gardes_google.push({
												date: moment(evenement.dtstart.value).format('YYYY-MM-DD'),
												periode: periode
											})
										}
									}
									//	Si garde 24h
									else if(summary.includes('garde 24h') && indexCreneauNuit >= 0 && indexCreneauJour >= 0) {
										planning[indexPlanning].creneaux[indexCreneauNuit].garde = true
										planning[indexPlanning].creneaux[indexCreneauJour].garde = true
										
										//	ajout à la liste des gardes pour Google
										const periode = this.creneaux.find( creneau => creneau.nom.toLowerCase() == 'jour')
										if(periode) {
											gardes_google.push({
												date: moment(evenement.dtstart.value).format('YYYY-MM-DD'),
												periode: {
													heure_debut:"07:00:00",
													heure_fin:"19:00:00",
													id:1,
													id_centre:1,
													nom:"24H"
												}
											})
										}
									}
									


									
									// //	pour trouver les creneaux équivalent, on cherche si le mot "jour" ou "nuit" apparait dans le "summary"
									// const indexCreneau = planning[indexPlanning].creneaux.findIndex( elem => evenement.summary.toLowerCase().includes(elem.nom_creneau.toLowerCase()))
									// //	si le créneau est trouvé
									// if(indexCreneau >= 0){
									// 	if(evenement.summary.toLowerCase().includes('dispo')) planning[indexPlanning].creneaux[indexCreneau].dispo = true
									// 	if(evenement.summary.toLowerCase().includes('garde') && !evenement.summary.toLowerCase().includes('codis')) planning[indexPlanning].creneaux[indexCreneau].garde = true

									// 	//	si de garde on ajoute à la liste des gardes pour Google
									// 	if(evenement.summary.toLowerCase().includes('garde') && !evenement.summary.toLowerCase().includes('codis')) {
									// 		const periode = this.creneaux.find( creneau => evenement.summary.toLowerCase().includes(creneau.nom.toLowerCase()))
									// 		if(periode) {
									// 			gardes_google.push({
									// 				date: moment(evenement.dtstart.value).format('YYYY-MM-DD'),
									// 				periode: periode
									// 			})
									// 		}
									// 	}
									// }
								}
							}
						
						}

						// 	//	initialisation du tableau de gardes pour l'agenda Google
						this.liste_gardes = gardes_google
						// 	this.liste_gardes = this.planning_courant.plannifications[0].gardes.filter( date => date.garde == true)
						//}
					}
				}
				this.gardes_dispos = planning
			},
			initMessages() {
				//	permet de rajouter un paramètre "afficher_detail" à chaque message
				this.liste_messages_entrants = this.messages_membre.entrant.map( message => {
					return {
						...message,
						afficher_detail: false
					}
				})
				this.liste_messages_sortants = this.messages_membre.sortant.map( message => {
					return {
						...message,
						afficher_detail: false
					}
				})
			},
			mois_precedent() {
				this.chargement_mois_precedent = true
				this.$refs.calendar.prev()
				this.affichage_zone_remplacants = false
			},
			mois_suivant() {
				this.chargement_mois_suivant = true
				this.$refs.calendar.next()
				this.affichage_zone_remplacants = false
			},
			montrerEchange(garde) {
				this.echange_courant.date = garde.date
				this.echange_courant.id_creneau = garde.id_periode
			},
			onClickCreneau(date, creneau) {
				const indexPlanning = this.gardes_dispos.findIndex( element => element.jour == date)
				//	si le centre est géré avec AGATT on ne fait rien
				if(indexPlanning >= 0 && !this.gestion_agatt) {
					const indexCreneau = this.gardes_dispos[indexPlanning].creneaux.findIndex( elem => elem.id_creneau == creneau.id)
					//	si le créneau est trouvé
					if(indexCreneau >= 0){
						// en mode d'affichage dispo on inverse l'état si la date n'est pas passée
						if(this.mode_affichage == 'dispos') {
							const jourGarde = this.gardes_dispos[indexPlanning]
							if(moment(jourGarde.jour).isBefore(moment(), 'day')) {
								this.snackbar('info', 'La date est passée')
							}
							else {
								this.gardes_dispos[indexPlanning].creneaux[indexCreneau].dispo = !this.gardes_dispos[indexPlanning].creneaux[indexCreneau].dispo
							}
							
						}

						// en mode gardes on gère les remplacements
						else if(this.mode_affichage == 'gardes') {
							const jourGarde = this.gardes_dispos[indexPlanning]
							
							//	si la date est passée on ne fait rien
							//if(1==0){
							if(moment(jourGarde.jour).isBefore(moment(), 'day')) {
								this.snackbar('info', 'La date est passée')
								this.affichage_zone_remplacants = false
							}
							else {
								this.remplacement.date = moment(jourGarde.jour).format('dddd DD/MM/YYYY')
								this.remplacement.id_garde = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].id_garde
								this.remplacement.nom_creneau = this.gardes_dispos[indexPlanning].creneaux[indexCreneau].nom_creneau.toLowerCase()
								this.affichage_zone_remplacants = true

								//	en mode portable
								if(this.affichagePortable) {
								// si la boite de dialogue detail n'est pas ouverte on l'ouvre
									if(!this.openDialogueDetailJour) {
										this.onClickJour(date)
									}
									else {
										this.affichage_zone_remplacants_portable = true
										this.chercherRemplacant(this.gardes_dispos[indexPlanning].creneaux[indexCreneau].id_garde)
									}
								}

								//	en mode bureau on lance la recherche de remplacants
								else {
									this.chercherRemplacant(this.gardes_dispos[indexPlanning].creneaux[indexCreneau].id_garde)
								}
								
							}
							
						}
					}
				}

			},
			onClickJour(date) {
				if(this.affichagePortable) {
					this.affichage_zone_remplacants_portable = false
					this.chargerDetailJour(date)
					this.openDialogueDetailJour = true					
				}
				
			},
			onClickSauvDispos() {
				if(this.mode_affichage == 'dispos') {
					this.chargement_sauvegarde = true
					axios.post('api/planning/sauv_dispos', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.$store.getters.membreCourant.centre.id, id_planning: this.planning_courant ? this.planning_courant.id : null, id_plannification: this.planning_courant && this.planning_courant.plannifications.length >0 ? this.planning_courant.plannifications[0].id : null, commentaire: this.commentaire_planning, dispos: this.gardes_dispos, date_debut: this.start.date })
                    .then( (reponse) => {
						this.snackbar('success',reponse.data.resultat)
                    })
                    .catch( (error) => {
                        if(error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors de la sauvegarde des dispos')
						}
					})
                    .then(() => {
						this.chargement_sauvegarde = false
						this.fetchDisposGardes(0)
						
                    })
				}
			},
			onClickSauvGoogle() {
				this.chargement_google = true
				let liste_requetes_suppression = []
				this.$gapi.getGapiClient().then((gapi) => {
					
					//pour mettre a jour on supprime tous les evenements de l'agenda google déja inscrits et on ajoute les gardes du planning courant
					const date_min = this.start.date+'T09:00:00+01:00'
					const date_max = this.end.date+'T21:00:00+01:00'
					//let liste_id_supprimer = []
					
					//recuperer la liste des événements créés dans l'agenda google par la source "SP Planning" pour le mois concerné
					const requete_suppression_liste = gapi.client.calendar.events.list({
						'calendarId': 'primary',
						'timeMax': date_max,
						'timeMin': date_min,
						'showDeleted': false,
						'singleEvents': true,
						'orderBy': 'startTime',
						'privateExtendedProperty': 'origine=SP Planning' //pour ne filtrer que les evenements créés par SP Planning
					})

					requete_suppression_liste.execute((resp) => {
						//fonction exécutée quand la liste est faite
						const events = resp.items
						if (events.length > 0) {
							this.supprimerEvents(gapi, events)
						} 
						else {
							console.log('Aucun événements à supprimer');
						}
					})
					
					
					
					//	insertion des gardes du mois
					if(this.liste_gardes.length > 0) {
						this.ajouterEvents(gapi)
						
						// const promises = []
						// for (const garde of this.liste_gardes) {
						// 	let debut_garde = ''
						// 	let fin_garde = ''
						// 	let rappel = 1440	//24h par défaut

						// 	//	si heure debut > heure fin, la garde se termine le jour d'apres
						// 	if(moment(garde.periode.heure_debut, 'HH:mm:ss').isAfter(moment(garde.periode.heure_fin, 'HH:mm:ss'))) {
						// 		debut_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_debut //décalage horaire par rapport a GMT non précisé car géré par l'attribut 'timezone'
						// 		fin_garde = moment(garde.date).add(1,'days').format('YYYY-MM-DD')+'T'+garde.periode.heure_fin
						// 	}
						// 	else {
						// 		debut_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_debut //décalage horaire par rapport a GMT non précisé car géré par l'attribut 'timezone'
						// 		fin_garde = moment(garde.date).format('YYYY-MM-DD')+'T'+garde.periode.heure_fin
						// 	}

						// 	//	si l'heure de début est < 8:00:00 on fait un rappel 12h avant
						// 	if(moment(garde.periode.heure_debut, 'HH:mm:ss').isBefore(moment('08:00:00', 'HH:mm:ss')) ) {
						// 		rappel = 720
						// 	}
						// 	//	si l'heure de début est > 15:00:00 on fait un rappel 62h avant
						// 	else if(moment(garde.periode.heure_debut, 'HH:mm:ss').isAfter(moment('15:00:00', 'HH:mm:ss'))) {
						// 		rappel = 360
						// 	}

						// 	const evenement = {
						// 		'summary': 'Garde CSP',
						// 		'source':{
						// 			'title':'SP Planning',
						// 			'url':'https://spplanning.fr'
						// 		},
						// 		'start': {
						// 			'dateTime': debut_garde,
						// 			'timeZone': 'Europe/Paris'
						// 		},
						// 		'end': {
						// 			'dateTime': fin_garde,
						// 			'timeZone': 'Europe/Paris'
						// 		},
						// 		'reminders': {
						// 				'useDefault': false,
						// 				'overrides': [
						// 					{'method': 'popup', 'minutes': rappel}   
						// 					]
						// 		},
						// 		'colorId': 11,
						// 		'extendedProperties':{
						// 			'private':{
						// 				'origine':'SP Planning'
						// 			}
						// 		}
						// 	}

						// 	// const requete_insertion = gapi.client.calendar.events.insert({
						// 	// 	'calendarId': 'primary',
						// 	// 	'resource': evenement
						// 	// })
							
						// 	// this.sleep(1500).then( () => {
						// 	// 	requete_insertion.execute()
						// 	// })

							

						// 	// setTimeout(
						// 	// 	promises.push(
						// 	// 		gapi.client.calendar.events.insert({
						// 	// 			'calendarId': 'primary',
						// 	// 			'resource': evenement
						// 	// 		})
						// 	// 	), 500
						// 	// )
						// }

						// Promise.all(promises).then(() => {
						// 	const texte = promises.length > 1 ? ' gardes ajoutées' : ' garde ajoutée'
						// 	this.snackbar('success', promises.length + texte);
						// })
						// .catch( (erreur) => {

						// })
						// .then( () => {
						// 	this.chargement_google = false
						// })
						
					}			
				})
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			resetEchangeCourant() {
				this.echange_courant.date = null
				this.echange_courant.id_creneau = null
			},
			sleep(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			async supprimerEvents(gapi, liste) {
				for (const event of liste) {
					await this.sleep(500)
					const requete_suppression = gapi.client.calendar.events.delete({
						calendarId: 'primary',
						eventId: event.id
					})
					requete_suppression.execute()
				}
			},
			surveilleEnregistrementGoogle() {
                this.$gapi.getGapiClient().then((gapi) => {
					// On surveille les changements d'enregistrement google calendar
					gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)

					//	on vérifie l'enregistrement actuel
					this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
                    
                })
            },
			texteCard(date, creneau) {
				// retourne le texte à afficher dans la case de couleur dispo/garde et le type (icone ou texte)
				const jour = this.gardes_dispos.find( jour => jour.jour == date)
				if(jour) {
					const periode = jour.creneaux.find( elem => elem.id_creneau == creneau.id)
					if(periode) {
						if(this.mode_affichage == 'dispos' && periode.dispo == true) {
							return {
									texte: this.premiereLettreMaj(creneau.nom),
									type: 'texte'
								}
						}
						else if(this.mode_affichage == 'gardes' && periode.garde == true) {

							return {
									texte: this.premiereLettreMaj(creneau.nom),
									type: 'texte'
								}
						}
					}
					
				}

				//	si c'est un jour d'échange à montrer
				if(date == this.echange_courant.date && creneau.id == this.echange_courant.id_creneau) {
					return {
						texte: 'mdi-swap-horizontal-bold',
						type: 'icone'
					} 
				}
				
				//	sinon on ne renvoie rien
				return {
					texte: '',
					type: 'texte'
				}
			},
			texteEchange(date, nom_creneau) {
				return moment(date).format('dddd DD/MM/YYYY')+' ('+nom_creneau.toLowerCase()+')'
			},
			updateRange ({ start, end }) {
				// You could load events from an outside source (like database) now that we have the start and end dates on the calendar
				// on met à jour les date de début et fin de mois
				this.start = start
				this.end = end
				this.fetchDisposGardes()
				this.fetchDisposGardesAGATT()
				
			},
			updateSigninStatus(isSignedIn) {
                this.activation_agenda_google = isSignedIn
            }

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
				
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces planning spv')) {
					this.$router.push('/')
				}
				else {
					this.fetchDisposGardes()
					this.fetchDisposGardesAGATT()
				}
			},
			mode_affichage() {
				this.affichage_zone_remplacants = false
			}
		}
  	}
</script>

<style scoped>

.liste_dispo_remplacant {
	max-height: calc(100vh - 270px);
	min-height: calc(100vh - 270px);
    overflow-y: auto;
    overflow-x: hidden;
    
}

.message_astreinte_detail {
	min-height: 24px;
}

</style>

<style>
.bouton_dispos {
	color:#7E57C2 !important;
}

.bouton_gardes {
	color:#7CB342 !important;
}

.case_echange {
	border: 5px solid #C5E1A5 !important; 
}

#calendrier_dispos_gardes {
	border: 0 !important;
}

/* #calendrier_dispos_gardes .v-calendar-weekly__week{
	border-left: #e0e0e0 1px solid;
} */

#calendrier_dispos_gardes .v-calendar-weekly__head-weekday {
	background-color: #FAFAFA;
	color: #212121;
	padding-bottom: 5px;
	border-right: none;
	border-bottom: #e0e0e0 1px solid;
}

#calendrier_dispos_gardes .v-outside {
	background-color: #FAFAFA;
	border-right: none;
	border-bottom: none;
}

#calendrier_dispos_gardes .v-outside div{
	color: #FAFAFA;
}

#calendrier_dispos_gardes .v-calendar-weekly__week .v-outside .v-calendar-weekly__day-label{
	display: none
}

#calendrier_dispos_gardes .v-calendar-weekly__day-label{
	margin: 0
}


#calendrier_dispos_gardes .v-future .v-calendar-weekly__day-label button, #calendrier_dispos_gardes .v-past .v-calendar-weekly__day-label button{
	background-color: transparent;
}


/* #calendrier_dispos_gardes .v-present div{
	color: #C2185B;
} */

.calendrier_portable .v-calendar-weekly__week {
	min-height: 80px;
}

.calendrier_normal .v-calendar-weekly__week {
	min-height: 95px;
}

.textCardJour {
	height: 100%;
	text-align: center;
	
}

.zone_gardes_dispos_normal {
	height: calc(50% - 2px);
}

.zone_gardes_dispos_portable {
	height: calc(50% - 10px);
}

.zone_jour {
	min-height: 50px !important;
}



</style>